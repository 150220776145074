import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import WhoWeAre from "../components/WhoWeAre"
import Services from "../components/Services"
import HelpYourChild from "../components/HelpYourChild"
import Work from "../components/Work"
import Schedule from "../components/Schedule"
import HowToFind from "../components/HowToFind"
import Footer from "../components/Footer"
import Faq from "../components/faq/FAQ"

const IndexPage = () => (
  <Layout>
    <Seo title="Giseli Abila | Psicopedagoga Infantil" />
    <Header/>
    {/* <WhoWeAre/>
    <Services/>
    <HelpYourChild/>
    <Work/>
    <Schedule/>
    <HowToFind/>
    <Faq/>
    <Footer/> */}
  </Layout>
)

export default IndexPage
