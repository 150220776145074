import React, { useState } from "react"
import psicopedVideo from "../videos/psicopedgiseliabila-briefing.mp4"
import data from "../data"
import QuestionItem from "./faq/QuestionItem";
import SocialMedia from "./SocialMedia"

const Header = () => {
  const posterImage = "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/v1657766654/psicopedgiseliabila/psicopedgiseli_abila_video_thumbnail_du7eso.jpg"
  const treatmentImageSrc = "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,g_face,h_200,q_100,r_max,w_200/v1657587223/psicopedgiseliabila/service-one-MINIFIED_viitjn.webp"

  const fileSources = [
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,h_250,q_100,w_350/v1657587235/psicopedgiseliabila/work-1_fncky1.webp", imgAlt: data.workOneImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,h_250,q_100,w_350/v1657587235/psicopedgiseliabila/work-2_lgprwa.webp", imgAlt: data.workTwoImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,h_250,q_100,w_350/v1657729592/psicopedgiseliabila/work-3_ym3ehv.webp", imgAlt: data.workThreeImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,h_250,q_100,w_350/v1657587235/psicopedgiseliabila/work-4_afx0as.webp", imgAlt: data.workFourImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,h_250,q_100,w_350/v1657587236/psicopedgiseliabila/work-5_owjoar.webp", imgAlt: data.workFiveImageAlt},
    {imgSrc: "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,h_250,q_100,w_350/v1657587235/psicopedgiseliabila/work-6_mt5q5f.webp", imgAlt: data.workSixImageAlt}
  ]

  const imageWhoAmISrc = "https://res.cloudinary.com/cdnimagesnatpsi/image/upload/c_thumb,g_face,h_278,q_100,r_max,w_278/v1657587228/psicopedgiseliabila/who-we-are_qasbry.webp"

  const [clicked, setClicked] = useState("0");
  const handleToggle = (index) => {
      if (clicked === index) {
          return setClicked("0");
      }
      setClicked(index);
  };


  return (
    <div className="section">
      <div class="custom-shape-divider-top-1667181236">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
        </svg>
      </div>
      <div className="main-container zero-container">
        {/* 1 */}
        {/* O suporte que o seu filho precisa para desenvolver autonomia e sucesso na vida escolar.  */}
        <div className="section-content">
          <h1 className="main-color-second">O suporte que o seu filho precisa para desenvolver autonomia e sucesso na vida escolar.</h1>
          <h2 className="h2-default main-color-second-darker">O diagnóstico precoce de possíveis déficits de aprendizagem possibilita uma formação efetiva durante a vida escolar do seu filho, transformando seu desempenho nos estudos.</h2>
          <p className="mt-3"><strong className="main-color-second-darker">Consulta 100% personalizada</strong></p>
        </div>
        <div>
          <div className="video-content">
            <video 
              controls 
              disablepictureinpicture
              disableremoteplayback Experimental
              poster={posterImage}
              height="100%" 
              width="100%">
                <source src={psicopedVideo} type="video/mp4" />
                <track kind="captions"/>
                Nos desculpe, seu navegador não suporta o vídeo.
            </video>
          </div>
          <div className="buttons-column">
            <a className="first-pattern" target="_blank" href={data.whatsappLink} >Agendar minha consulta</a>
          </div>
        </div>
      </div>

      {/* 2 */}
      {/* Afinal, como a psicopedagogia pode ajudar e por que o acompanhamento profissional é importante?  */}
      <div className="container-colored pt-3 pb-3">
        <div className="main-container first-container">
          <div className="section-content">
            <h1 className="main-color-second">Afinal, como a psicopedagogia pode ajudar e por que o acompanhamento profissional é importante? </h1>
            <p className="main-color-second-darker">Muitas crianças e adolescentes apresentam dificuldades de aprendizagem. Isso pode ocorrer em decorrência de vários fatores, como: <strong className="main-color-second-darker">a falta de concentração</strong>, <strong className="main-color-second-darker">a timidez</strong> e <strong className="main-color-second-darker">fatores emocionais</strong>.</p>
            <h2 className="h2-default main-color-second-darker">Entretanto, a dificuldade na aprendizagem <strong className="main-color-second-darker">é apenas um sintoma e não a causa.</strong></h2>
            <p className="main-color-second-darker">No atendimento psicopedagógico é possível investigar a causa dos problemas de aprendizagem e realizar intervenções para que sejam resolvidos.</p>
          </div>
        </div>
      </div>

      {/* 3 */}
      {/* O tratamento psicopedagógico ajudará o seu filho a desenvolver */}
      <div className="pt-3 pb-3">
        <div className="main-container second-container">
          <div className="section-content">
            <div className="image-wrapper">
              <img className="round-img lazyload" data-src={treatmentImageSrc} alt={data.serviceOneImageAlt} />
            </div>
            <div className="text-wrapper">
              <h1>O atendimento psicopedagógico ajudará o seu filho a desenvolver:</h1>
              <ul className="list-column">
                <li>A <strong>leitura</strong>, <strong>escrita</strong> e solução de <strong>problemas matemáticos</strong></li>
                <li>A capacidade de buscar respostas por conta própria, superando suas dificuldades</li>
                <li>O comportamento no <strong>ambiente escolar e social</strong></li>
                <li>O controle motor e o seu lado psicológico e afetivo</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* 4 */}
      {/* Serviços */}
      <div className="container-colored pt-3 pb-3">
      <div className="main-container third-container">
        <div className="content">
          <h1 className="main-color-second">Serviços</h1>
          <h2 className="h2-default main-color-second-darker">Cada criança é única e necessita de incentivos específicos para alcançar bons resultados nos estudos. Assim, identificamos quais são essas necessidades, para intervir da melhor forma possível.</h2>
          <p className="main-color-second-darker">Os atendimentos incluem: </p>
          <div className="services-list">
            <div className="list-group">
              {/* 1 */}
              <div className="list-item">
                <h3 className="main-color-second-darker">+ O diagnóstico e intervenção nas causas que afetam o aprendizado</h3>
              </div>
              {/* 2 */}
              <div className="list-item">
                <h3 className="main-color-second-darker">+ Orientação e aconselhamento dos pais</h3>
              </div>
            </div>
            <div className="list-group">
              {/* 3 */}
              <div className="list-item">
                <h3 className="main-color-second-darker">+ Formação continuada para educadores</h3>
              </div>
              {/* 4 */}
              <div className="list-item">
                <h3 className="main-color-second-darker">+ Atividades que auxiliam no desenvolvimento da memória, foco, atenção, tomada de decisão e raciocínio lógico</h3>
              </div>
            </div>

            <div className="list-group">
              {/* 5 */}
              <div className="list-item">
                <h3 className="main-color-second-darker">+ Aplicação da terapia ABA para alfabetização de crianças dentro do espectro autista</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-column mt-5">
          <a className="first-pattern" target="_blank" href={data.whatsappLink}>Quero agendar uma consulta</a>
        </div>
      </div>
      </div>
      
      {/* 5 */}
      {/* Como saber se o meu filho enfrenta alguma dificuldade? */}
      <div className="pt-3">
        <div className="main-container fourth-container">
          <div className="section-content">
            <h1>Como saber se o meu filho enfrenta alguma dificuldade? </h1>
            <h2 className="h2-default">Existem diversos sinais que indicam bloqueios no processo de aprendizagem de crianças e adolescentes, alguns deles são:</h2>
            <ul className="list-column">
              <li>A falta de <strong>motivação</strong> em aprender coisas novas</li>
              <li>Dificuldade na hora de <strong>escrever, ler e fazer cálculos matemáticos</strong></li>
              <li>Irritabilidade ou crises de choro ao precisar fazer os deveres passados pela escola</li>
              <li><strong>Notas baixas</strong></li>
              <li>Problemas de socialização no ambiente escolar</li>
            </ul>
          </div>
        </div>
      </div>
      
      {/* Fotos Espaço de Atendimento */}
      <div className="main-container fifth-container">
        <div className="grid">
          {fileSources.map((imageObject, index) => (
            <img className="lazyload"
              data-src={imageObject.imgSrc} 
              alt={imageObject.imgAlt} 
              key={index} />
          ))}
        </div>
      </div>

      {/* 6 */}
      {/* Você tem um filho autista e sonha em vê-lo com mais independência? */}
      <div className="container-colored-red pt-3 pb-3">
        <div className="main-container sixth-container">
          <div className="content">
            <h1>Você tem um filho autista e sonha em vê-lo com mais independência?</h1>
            <p>Na clínica trabalhamos com a terapia comportamental especializada para o autismo <strong className="highlight-text-color">(terapia ABA)</strong>, comprovada como a ciência mais eficaz para a aprendizagem de crianças autistas.</p>
            <p>Assim, a terapia ABA vem para auxiliar o seu filho no <strong className="highlight-text-color">aprendizado</strong>, <strong className="highlight-text-color">comportamento</strong> e <strong className="highlight-text-color">comunicação</strong>.</p>
          </div>
        </div>
      </div>

      {/* 7 */}
      {/* Veja o que as mães estão dizendo: */}
      <div className="main-container nineth-container">
        <div className="section-content">
          <h1>Veja o que as mães estão dizendo: </h1>
          <div className="grid-list">
            <div className="post">
              <p>“O trabalho que a Giseli faz com meu filho de 16 anos é um divisor em sua vida escolar. A avaliação do caso, o direcionamento e os treinamentos que ele recebe, o contato com a escola e a orientação a nós familiares está sendo fundamental para a melhoria contínua do desempenho escolar, além do resgate de sua autoestima e da perspectiva do seu futuro acadêmico.”</p>
              <p className="stars">★★★★★ (5)</p>
              <span className="author">Sheyla Frioli Gottardo</span>
            </div>
            <div className="post">
              <p>“A profissional é maravilhosa, meu filho se prepara para ir no horário do atendimento. A evolução dele está nos surpreendendo. Ambiente, limpo e lindo, totalmente preparado para as crianças.  Parabéns!!!”</p>
              <p className="stars">★★★★★ (5)</p>
              <span className="author">Eliana Guerberoff</span>
            </div>
            <div className="post">
              <p>“Estou amando as sessões que estamos fazendo com minha filha, ela é autista tem 4 anos teve vários atrasos e a Gi está nos ajudando muito, a layan (minha filha) está evoluindo, sou muito grata a você Gi.”</p>
              <p className="stars">★★★★★ (5)</p>
              <span className="author">Sara Abo Nassif</span>
            </div>
          </div>
        </div>
        <div className="buttons-column mt-5">
          <a className="second-pattern" target="_blank" href={data.whatsappLink}>Agendar consulta</a>
        </div>
      </div>

      {/* 8 */}
      {/* Quem sou: */}
      <div className="container-colored-second pt-3 pb-3">
        <div className="tenth-container main-container">
          <div className="section-content">
            <div className="image-wrapper">
              <img className="round-img lazyload" data-src={imageWhoAmISrc} alt={data.whoWeAreImageAlt} />
            </div>
            <div className="text-wrapper">
              <h1>Quem sou</h1>
              <p>Olá, meu nome é Giseli Abila, sou pedagoga, psicopedagoga clínica e especializada em <strong>transtornos de neurodesenvolvimento</strong>, <strong>comorbidade</strong> e <strong>inclusão escolar no autismo</strong> (ABPp nr 820 e CBO 2394-25).</p>
              <p>Ao atuar em sala de aula, descobrindo as diversidades de talentos e as dificuldades de aprendizagem que, muitas vezes, não podiam ser resolvidas na escola, percebi a necessidade que muitas crianças têm de um atendimento individualizado.</p>
              <p>Por esse motivo, e por saber que inúmeras crianças precisam de apoios e incentivos específicos, decidi seguir o caminho da psicopedagogia clínica e institucional.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-3 pb-3">
        <div className="thirteenth-container main-container">
          <div className="section-content">
            <h2 className="main-color-second">Minha missão é ser um instrumento de auxílio que impulsione o seu filho em direção à <strong className="main-color-second">liberdade</strong>, <strong className="main-color-second">autonomia</strong> e <strong className="main-color-second">sucesso</strong> na vida.</h2>
          </div>
        </div>
      </div>

      {/* 9 */}
      {/* FAQ: */}
      <div className="container-colored pt-3 pb-3">
        <div className="eleventh-container main-container">
          <div className="content">
            <h1 className="main-color-second">FAQ - DÚVIDAS FREQUENTES</h1>
            <div className="accordion-wrapper">
              <ul>
                {data.faqs.map((faq, index) => (
                    <QuestionItem 
                        key={index} 
                        faq={faq} 
                        onToggle={() => handleToggle(index)}
                        active={clicked === index}/>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Footer: */}
      {/* Lista */}
      <div className="pb-3">
        <div className="twelveth-container main-container">
          <div className="content">
            <SocialMedia/>
            <p><strong>© 2022 Psicopedagogia Infantil</strong> - Giseli Abila -  Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
